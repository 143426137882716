import React from 'react';
import { DOTS } from './pagination-model';
import { usePaginationRange } from './usePaginationRange';

interface Props {
    onPageChange: (pageNumber: string | number) => any;
    totalCount: number;
    siblingCount?: number;
    currentPage: number;
    pageSize: number;
}

/*
Renders pagination based on range. Inspired by https://www.freecodecamp.org/news/build-a-custom-pagination-component-in-react/
 */
export function Pagination(props: Props) {
    const { onPageChange, totalCount, siblingCount, currentPage, pageSize } = props;


    const paginationRange = usePaginationRange({ currentPage, totalCount, siblingCount, pageSize });

    // If there are less than 2 times in pagination, don't render
    if (currentPage === 0 || paginationRange.length < 2) {
        return null;
    }

    const onNext = (disabled: boolean = false) => {
        if (disabled) return;

        onPageChange(currentPage + 1);
    };

    const onPrevious = (disabled: boolean = false) => {
        if (disabled) return;

        onPageChange(currentPage - 1);
    };

    let lastPage = paginationRange[paginationRange.length - 1];
    let dotsCount = 0;
    return (
        <ul className='pagination-container'>
            {/* Left navigation arrow */}
            <li>
                <button
                    type='button'
                    className={`pagination-item un-button ${(currentPage === 1 ? 'disabled' : '')}`}
                    onClick={() => onPrevious(currentPage === 1)}
                    disabled={currentPage === 1}
                    data-testid='arrow-left'>
                    <div className='arrow left' />
                </button>
            </li>
            {paginationRange.map(pageNumber => {

                // If the pageItem is a DOT, render the DOTS unicode character
                if (pageNumber === DOTS) {
                    return <li key={pageNumber + ++dotsCount} className="pagination-item dots">&#8230;</li>;
                }

                // Pages
                return (
                    <li key={pageNumber}>
                        <button
                            type='button'
                            className={`pagination-item un-button ${(pageNumber === currentPage ? 'selected' : '')}`}
                            onClick={() => onPageChange(pageNumber)}>
                            {pageNumber}
                        </button>
                    </li>
                );
            })}
            {/*  Right Navigation arrow */}
            <li>
                <button
                    type='button'
                    className={`pagination-item un-button ${(currentPage === lastPage ? 'disabled' : '')}`}
                    onClick={() => onNext(currentPage === lastPage)}
                    disabled={currentPage === lastPage}
                    data-testid='arrow-right'>
                    <div className='arrow right' />
                </button>
            </li>
        </ul>
    );
}

import React from 'react';
import { useDispatch } from 'react-redux';
import { Pagination } from '../../global/features/pagination/pagination';
import { setCurrentPage } from '../dynamic-list-slice';
import { getSortedPages } from '../helpers/sorting-helpers';
import { DynamicListContentPage, EpiProps, SortOptions } from '../models/dynamic-list-app-model';
import { DynamicListResult } from './dynamic-list-result';

interface Props {
    epiProps: EpiProps;
    pages: DynamicListContentPage[];
    categoryFilter: string;
    currentPage: number;
}
export function DynamicListResults(props: Props) {
    const { epiProps, pages, categoryFilter, currentPage } = props;

    const dispatch = useDispatch();

    const onPageChange = (page: number | string) => {
        dispatch(setCurrentPage(page as number));
    }

    // filter pages by the selected category, if a category is selected
    const filteredPages = pages.filter(page => !categoryFilter || page.categoryNames.includes(categoryFilter));

    const pageSize = epiProps.pageSize || 10;
    const paginatedPages = filteredPages.slice((currentPage - 1) * pageSize, (currentPage - 1) * pageSize + pageSize);

    return (
        <div className='dynamic-list-block-section'>
            <ul className='dynamic-list-block-results'>
                {paginatedPages.map((page, i) => <DynamicListResult key={page.title + i} page={page} />)}
            </ul>
            <div className='dynamic-list-block-pagination'>
                <Pagination
                    currentPage={currentPage}
                    totalCount={filteredPages.length}
                    pageSize={pageSize}
                    onPageChange={onPageChange}
                />
            </div>
        </div>

    );
}
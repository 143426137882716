import React from 'React';
import { useDispatch, useSelector } from 'react-redux';
import { SfoButton } from '../../global/components/sfo-button';
import { ButtonThemes } from '../../global/models/component-configs';
import { setCurrentPage, setSelectedCategoryFilter } from '../dynamic-list-slice';
import { DynamicListAppState, DynamicListContent, DynamicListState, EpiProps } from '../models/dynamic-list-app-model';

export function DynamicListFilters() {
    const dispatch = useDispatch();
    const epiContent = useSelector<DynamicListAppState, EpiProps>(state => state.epiProps.epiContent);
    const listState = useSelector<DynamicListAppState, DynamicListState>(state => state.dynamicListState);


    if (!listState.dynamicListContent.uniqueCategoryNames || listState.dynamicListContent.uniqueCategoryNames.length < 2) return null;

    const onFilterClick = (category: string) => {

        const filterToSet = listState.selectedCategoryFilter === category ? '' : category;

        dispatch(setSelectedCategoryFilter(filterToSet));
    };

    return (

        <div className='dynamic-list-block-filters'>
            <h5>{epiContent.categoryFiltersLabel}</h5>
            <div className='stack space-top-md dynamic-list-block-filters-list'>
            {listState.dynamicListContent.uniqueCategoryNames.map((category: string) => {
                const selected = listState.selectedCategoryFilter === category;
                return (
                    <div key={category} className='filter-option' >
                        <SfoButton text={category} onClick={() => onFilterClick(category)} theme={selected ? ButtonThemes.PillCheckmarkActive : ButtonThemes.PillCheckmark} />
                    </div>
                );
            })}
                </div>
        </div>
    );
}
import { AnyAction, Dispatch } from 'redux';
import { ApiAction } from '../models/api-model';
import { EpiApiActionTypes } from '../models/epi-model';
import { apiAction } from './base-api-slice';

export const epiApiReducerWithDefault = <TEpiState>(defaultState: TEpiState) => (state = defaultState, action: AnyAction) => {
    switch (action.type) {
        case EpiApiActionTypes.SET_EPI_PROPS:
            return {
                ...state,
                epiContent: action.payload,
                contentLoaded: true,

            };
        case EpiApiActionTypes.SET_EPI_PROPS_FAILED:
            return {
                ...state,
                contentLoaded: true,
            };

        default:
            return state;
    }
};

const setEpiPropsFailed = () => {
    return {
        type: EpiApiActionTypes.SET_EPI_PROPS_FAILED,
    };
}

const dispatchSetEpiProps = (dispatch: Dispatch<any>) => (data: any) => {
    dispatch({
        type: EpiApiActionTypes.SET_EPI_PROPS,
        payload: data,
    });
};

const BaseUrl = '/api/v1/epicontent';
export const getEpiProps = (dispatch: Dispatch<any>, contentId: string, payloadData?: any, language: string = 'en', siteSettings: boolean = false, setter = dispatchSetEpiProps): ApiAction => {
    return apiAction({
        url: `${BaseUrl}/getbyid/?contentId=${contentId}&language=${language}`,
        onSuccess: setter(dispatch),
        onFailure: () => dispatch(setEpiPropsFailed()),
        payloadData,
    });
};
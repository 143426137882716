import { BaseApiState } from '../../global/models/api-model';
import { ContentPropsState } from '../../global/models/epi-model';



export interface EpiProps {
    title: string;
    description: string;
    categoryFiltersLabel: string;
    previousLabel: string;
    nextLabel: string;
    contentType: string;
    pageSize: number;
    contentSourcePageId: string;
    sortOrder: SortOptions;
}


export const DefaultEpiApiState: ContentPropsState<EpiProps> = {
    contentLoaded: false,
    epiContent: {} as EpiProps,
};

export interface DynamicListContentPage {
    title: string;
    description: string;
    pageLink: string;
    date: string;
    categoryNames: string[];
    treeDepth: number;
    imageUrl: string;
    hideImages: boolean;
}

export interface DynamicListContent {
    pages: DynamicListContentPage[];
    uniqueCategoryNames: string[];
}

export interface DynamicListState {
    contentLoaded: boolean;
    dynamicListContent: DynamicListContent;
    selectedCategoryFilter: string;
    currentPage: number;
}

export interface DynamicListAppState {
    apiState: BaseApiState;
    epiProps: ContentPropsState<EpiProps>;
    dynamicListState: DynamicListState;
  
 
}

export enum DynamicListActionTypes {
    SET_DYNAMIC_LIST_CONTENT = 'SET_DYNAMIC_LIST_CONTENT',
    SET_DYNAMIC_LIST_CONTENT_FAIL = 'SET_DYNAMIC_LIST_CONTENT_FAIL',

    SET_SELECTED_CATEGORY_FILTER = 'SET_SELECTED_CATEGORY_FILTER',
    SET_CURRENT_PAGE = 'SET_CURRENT_PAGE',

}

export enum SortOptions {
    PageTree = 'PageTree',
    Date = 'Date',
    DateDesc = 'DateDesc',
}
import React from 'react';
import { HtmlString } from '../../global/components/html-string';
import { DynamicListContentPage } from '../models/dynamic-list-app-model';

interface Props {
    page: DynamicListContentPage;
}
export function DynamicListResult(props: Props) {
    const { page } = props;

    const publishDate = page.date ? new Date(page.date) : null;

    return (
        <li className='dynamic-list-block-result stack-sm' data-testid='dynamic-list-block-result'>
            <article className="preview-card">
                <div className={!page.hideImages && page.imageUrl != "" ? "preview-card-main" : ""}>
                    {!page.hideImages && page.imageUrl != "" ?
                        <div className="preview-card-section">
                            <div className="preview-card-section-image">
                                <img src={page.imageUrl} />
                            </div>
                        </div>
                        : ""
                    }
                    <div className="preview-card-section">
                        <div className="flex-column-between">
                            <div className="stack-xs">
                                <span className='tag'>{publishDate?.toLocaleDateString('en-US') || ''}</span>
                                <a href={page.pageLink} >
                                    <h5><HtmlString value={page.title} /></h5>
                                </a>
                                <div className="rich-text">
                                    <HtmlString value={page.description} />
                                </div>
                            </div>
                       
                        </div>
                    </div>
                </div>
               
            </article>

        </li>
    );
}
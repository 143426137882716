import { AnyAction, Dispatch, MiddlewareAPI } from 'redux';
import axios, { AxiosError, Method } from 'axios';
import { ActionTypes, ApiAction, HttpMethods } from '../models/api-model';
import { apiError } from '../slices/base-api-slice';

export const apiMiddleware = ({ dispatch }: MiddlewareAPI<any>) => (next: Dispatch<AnyAction>) => (action: AnyAction) => {

	if (action.type !== ActionTypes.API) {
		return next(action);
	}

	const { url, method, payloadData, onStart, onEnd, onSuccess, onFailure, formData, contentType } = (action as ApiAction).payload;
	const dataOrParams = (method === HttpMethods.GET || method === HttpMethods.DELETE) ? 'params' : 'data';

	dispatch(onStart());
	axios.request({
		url,
		method: method as Method,
		headers: {
			'Content-Type': contentType,
			'Accept': '*/*',
		},
		[dataOrParams]: payloadData || formData,
	})
		.then(({ data }) => {
			onSuccess(data);
		})
		.catch((error: AxiosError) => {
			dispatch(apiError(error)); // handled in api error reducer
			onFailure();
		})
		.finally(() => {
			dispatch(onEnd());
		});

	return next(action);
};
import { AxiosError } from 'axios';
import { Action } from 'redux';

export enum ActionTypes {
    API = 'API',
    API_ERROR = 'API_ERROR',
    API_START = 'API_START',
    API_END = 'API_END',
    API_SUCCESS = 'API_SUCCESS',
    API_FAILURE = 'API_FAILURE',
    API_ERROR_CLEAR = 'API_ERROR_CLEAR',
    CLEAR_ERRORS_BY_CODE = 'CLEAR_ERRORS_BY_CODE',
}

export enum HttpMethods {
    GET = 'GET',
    POST = 'POST',
    PUT = 'PUT',
    PATCH = 'PATCH',
    DELETE = 'DELETE',
}

export enum ApiErrorTypes {
    None,
    General,
    Timeout,
}

export interface BaseApiState {
    error: AxiosError[];
    pendingRequests: string[];
}

export interface ApiActionPayload {
    url: string;
    method: string;
    payloadData: Object;
    accessToken: Object;
    onStart: () => any;
    onEnd: () => any;
    onSuccess: (data: any) => any;
    onFailure: () => any;
    label?: string;
    formData?: FormData;
    contentType?: string;
}

export interface ApiAction extends Action {
    payload: ApiActionPayload;
}

export interface ApiErrorAction extends Action {
    error: AxiosError;
}

export enum ApiStatuses {
    None,
    Success,
    Failure,
}

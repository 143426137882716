import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { apiMiddleware } from '../global/middleware/api-middleware';
import { baseApiReducer } from '../global/slices/base-api-slice';
import { epiApiReducerWithDefault } from '../global/slices/epi-slice';
import { DynamicListApp } from './components/dynamic-list-app';
import { DynamicListBlockContent } from './components/dynamic-list-block-content';
import { dynamicListReducer } from './dynamic-list-slice';
import { DefaultEpiApiState, DynamicListAppState } from './models/dynamic-list-app-model';


const dynamicListBlockMounts = document.querySelectorAll('[data-dynamic-list-block]');

dynamicListBlockMounts.forEach(mount => {

    const stateReducer = combineReducers<DynamicListAppState>({
        apiState: baseApiReducer,
        epiProps: epiApiReducerWithDefault(DefaultEpiApiState),
        dynamicListState: dynamicListReducer,
        
    });
    const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

    const stateStore = createStore(stateReducer, composeEnhancers(
        applyMiddleware(
            apiMiddleware,
        ),
    ));

    const blockId = mount.getAttribute('data-dynamic-list-block');
    const root = ReactDOM.createRoot(mount as HTMLElement);

    root.render(
        <Provider store={stateStore} >
            <DynamicListApp blockId={blockId} />
        </Provider>
    );
});
import React from 'React';

interface Props {
    loading: boolean;
}
export function LoadingSpinner(props: Props) {
    if (!props.loading) return null;

    return (
        <div className="spinner-container">
            <div className="loading-spinner">
            </div>
        </div>
    );
}
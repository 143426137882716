import React from 'react';
import { useSelector } from 'react-redux';
import { HtmlString } from '../../global/components/html-string';
import { LoadingSpinner } from '../../global/components/loading-spinner';
import { Pagination } from '../../global/features/pagination/pagination';
import { ContentPropsState } from '../../global/models/epi-model';
import { DynamicListAppState, DynamicListState, EpiProps, DynamicListContentPage } from '../models/dynamic-list-app-model';
import { DynamicListFilters } from './dynamic-list-filters';
import { DynamicListResults } from './dynamic-list-results';

export function DynamicListBlockContent() {

    const epiPropsState = useSelector<DynamicListAppState, ContentPropsState<EpiProps>>(state => state.epiProps);
    const dynamicListState = useSelector<DynamicListAppState, DynamicListState>(state => state.dynamicListState);

    const listState = useSelector<DynamicListAppState, DynamicListState>(state => state.dynamicListState);
    const hasFilters = (!listState.dynamicListContent.uniqueCategoryNames || listState.dynamicListContent.uniqueCategoryNames.length <= 1) ? false : true;
    const hasImages = dynamicListState.dynamicListContent?.pages.length > 0 ? dynamicListState.dynamicListContent?.pages[0].hideImages : false;

    const layoutClasses =
        hasImages && hasFilters || !hasImages && hasFilters ? "ace-layout ace-layout-8-4 reverse-mobile ace-layout-borderless-mobile" :
            hasImages && !hasFilters || !hasImages && !hasFilters ? "dynamic-list-block-content" : ""


    if (!epiPropsState.contentLoaded || !dynamicListState.contentLoaded) {
        return <LoadingSpinner loading={true} />;
    }

    const epiProps = epiPropsState.epiContent;


    return (
        <>
            <div className="block-header">
                <h2>{epiProps.title}</h2>
                <HtmlString value={epiProps.description} />
            </div>
            <div className={layoutClasses} >
                <DynamicListResults
                    epiProps={epiProps}
                    pages={dynamicListState.dynamicListContent?.pages || []}
                    categoryFilter={dynamicListState.selectedCategoryFilter}
                    currentPage={dynamicListState.currentPage}
                />

                <div className='dynamic-list-block-section'>
                    <DynamicListFilters />
                </div>





            </div>
        </>
    );
}
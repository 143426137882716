import React from 'React';
import { ButtonThemes } from "../models/component-configs";

interface Props {
    text: string;
    onClick?: (e) => any;
    theme?: ButtonThemes;
}
export function SfoButton(props: Props) {
    const { theme, text, onClick } = props;
    return (
        <button
            type='button'
            className={theme || ''}
            onClick={onClick}>{text}</button>
    );
}
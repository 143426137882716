import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ContentPropsState } from '../../global/models/epi-model';
import { getEpiProps } from '../../global/slices/epi-slice';
import { getDynamicListContentContent, setDynamicListContentContent, setDynamicListContentContentFail } from '../dynamic-list-slice';
import { DynamicListAppState, DynamicListContent, EpiProps } from '../models/dynamic-list-app-model';
import { DynamicListBlockContent } from './dynamic-list-block-content';

interface Props {
    blockId: string;
}

export function DynamicListApp(props: Props) {

    const { blockId } = props;

    const dispatch = useDispatch();

    const epiPropsState = useSelector<DynamicListAppState, ContentPropsState<EpiProps>>(state => state.epiProps);

    useEffect(() => {
        if (epiPropsState.contentLoaded) {
            if (!epiPropsState.epiContent?.contentSourcePageId) {
                dispatch(setDynamicListContentContentFail());
            }
            else {
                dispatch(getDynamicListContentContent(epiPropsState.epiContent.contentSourcePageId, blockId, epiPropsState.epiContent.sortOrder,
                    // success
                    (responseData: DynamicListContent) => dispatch(setDynamicListContentContent(responseData)),
                    // fail
                    () => dispatch(setDynamicListContentContentFail())
                ));
            }
        }
        else {
            dispatch(getEpiProps(dispatch, blockId));
        }
    }, [epiPropsState.contentLoaded]);

    return (
        <div className='block-inner dynamic-list-block'>
            <DynamicListBlockContent />
        </div>
    );
}
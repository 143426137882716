import { AnyAction, Dispatch } from 'redux';
import { ApiAction, HttpMethods } from '../global/models/api-model';
import { apiAction } from '../global/slices/base-api-slice';
import { DynamicListActionTypes, DynamicListContent, DynamicListState, SortOptions } from './models/dynamic-list-app-model';


const InitialState: DynamicListState = {
	contentLoaded: false,
	dynamicListContent: {
		pages: [],
		uniqueCategoryNames: [],
	
	},
	selectedCategoryFilter: '',
	currentPage: 1,


};

export const dynamicListReducer = (state = InitialState, action: AnyAction): DynamicListState => {
	switch (action.type) {
		case DynamicListActionTypes.SET_DYNAMIC_LIST_CONTENT: {
			return {
				...state,
				dynamicListContent: action.payload,
				contentLoaded: true,
			};
		}
		case DynamicListActionTypes.SET_DYNAMIC_LIST_CONTENT_FAIL: {
			return {
				...state,
				contentLoaded: true,
			};
		}
		case DynamicListActionTypes.SET_SELECTED_CATEGORY_FILTER: {
			return {
				...state,
				selectedCategoryFilter: action.payload,
				currentPage: 1,
			};
		}
		case DynamicListActionTypes.SET_CURRENT_PAGE: {
			return {
				...state,
				currentPage: action.payload,
			};
		}
		
		default: return state;
	}
};

export const getDynamicListContentContent = (sourceContentId: string, blockId: string, sortOrder: SortOptions, onSuccess?: (data: any, dispatch?: Dispatch<any>) => any, onFailure?: (dispatch?: Dispatch<any>) => any): ApiAction => {
	return apiAction({
		method: HttpMethods.GET,
		url: `/api/v1/dynamicListBlock/getContentFromSource/${sourceContentId}/?sortOrder=${sortOrder}&blockId=${blockId}`,
		onSuccess,
		onFailure,
	});
};

export const setDynamicListContentContent = (payload: DynamicListContent) => {
	return {
		type: DynamicListActionTypes.SET_DYNAMIC_LIST_CONTENT,
		payload,
	};
};

export const setDynamicListContentContentFail = () => {
	return {
		type: DynamicListActionTypes.SET_DYNAMIC_LIST_CONTENT_FAIL,
	};
};

export const setSelectedCategoryFilter = (category: string) => {
	return {
		type: DynamicListActionTypes.SET_SELECTED_CATEGORY_FILTER,
		payload: category,
	};
};

export const setCurrentPage = (currentPage: number) => {
	return {
		type: DynamicListActionTypes.SET_CURRENT_PAGE,
		payload: currentPage,
	};
};
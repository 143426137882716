import { AxiosError } from 'axios';
import { AnyAction } from 'redux';
import { ActionTypes, ApiAction, ApiErrorAction, BaseApiState, HttpMethods } from '../models/api-model';

const InitialState: BaseApiState = {
	error: null,
	pendingRequests: [],
};

const removeRequest = (pendingRequests: string[], request: string) => {
	const index = pendingRequests.indexOf(request);
	if (index === -1) return pendingRequests;

	return [
		...pendingRequests.slice(0, index),
		...pendingRequests.slice(index + 1),
	];
};

export const baseApiReducer = (state = InitialState, action: AnyAction): BaseApiState => {

	switch (action.type) {
		case ActionTypes.API_ERROR_CLEAR:
			return {
				...state,
				error: null,
			};
		case ActionTypes.API_START:
			return {
				...state,
				pendingRequests: [...state.pendingRequests, action.payload],
			};

		case ActionTypes.API_ERROR:
			return {
				...state,
				error: state.error ? [action.error, ...state.error] : [action.error],
			};
		case ActionTypes.API_END:
			return {
				...state,
				pendingRequests: removeRequest(state.pendingRequests, action.payload),
			};

		case ActionTypes.CLEAR_ERRORS_BY_CODE:
			return {
				...state,
				error: state.error.filter(x => x.response?.status !== action.payload),
			};
		default: return state;
	}
};

export const apiStart = (request: string): AnyAction => ({
	type: ActionTypes.API_START,
	payload: request,
});

export const apiEnd = (request: string): AnyAction => ({
	type: ActionTypes.API_END,
	payload: request,
});

export const defaultSuccess = (data: any): void => {

};

export const defaultFailure = (): void => {

};

export const apiAction = ({
	url = '',
	method = HttpMethods.GET,
	payloadData = null,
	accessToken = null,
	onSuccess = defaultSuccess,
	onFailure = defaultFailure,
	label = '',
	formData = null,
	contentType = 'application/json',
}): ApiAction => {
	return {
		type: ActionTypes.API,
		payload: {
			url,
			method,
			payloadData,
			accessToken,
			onStart: () => apiStart(url + method),
			onEnd: () => apiEnd(url + method),
			onSuccess,
			onFailure,
			label,
			formData,
			contentType,
		},
	};
};

export const apiError = (error: AxiosError): ApiErrorAction => ({
	type: ActionTypes.API_ERROR,
	error,
});


export const apiErrorClear = (): AnyAction => ({
	type: ActionTypes.API_ERROR_CLEAR,
});

export const clearErrorsByCode = (code: number) => {
	return {
		type: ActionTypes.CLEAR_ERRORS_BY_CODE,
		payload: code,
	};
};


